'use client';
import { Button } from '@alfred-co/ui/components';
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

const ErrorPage = () => {
  return (
    <div className='flex flex-row relative w-screen h-screen justify-center items-center gap-28'>
      <div className='absolute top-0 w-4/5 h-full bg-blue-radial-gradient' />
      <div className='flex flex-col justify-center items-center gap-10'>
        <span className='text-9xl'>500</span>
        <span className='text-[32px] text-center'>Error interno del servidor</span>
        <Link href='/'>
          <Button color='GradientAlfredBlue' className='w-[240px] h-[50px]'>
            Volver al inicio
          </Button>
        </Link>
      </div>
      <Image src='/assets/images/error500.svg' alt='loading' width={385} height={404} />
    </div>
  );
};

export default ErrorPage;
